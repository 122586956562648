<template>
  <b-container fluid>
    <!-- Status Cards -->
    <b-row class="mb-3">
  <b-col md="4" class="mb-2">
    <b-card
      title="Pending Reservations"
      class="text-start"
      variant="warning"
      :body-class="'d-flex flex-column align-items-start justify-content-center'"
      style="background-color: #ffcc00;"
    >
      <div class="d-flex align-items-center">
        <b-icon icon="clock" class="mr-2" font-scale="2" variant="light" />
        <h4 class="mb-0">{{ reservationStats.pending }}</h4> <!-- Keep count on the same line -->
      </div>
    </b-card>
  </b-col>

  <b-col md="4" class="mb-2">
    <b-card
      title="Reserved"
      class="text-start" 
      variant="success"
      :body-class="'d-flex flex-column align-items-start justify-content-center'" 
      style="background-color: #28a745;"
    >
      <div class="d-flex align-items-center">
        <b-icon icon="check-circle" class="mr-2" font-scale="2" variant="light" />
        <h4 class="mb-0">{{ reservationStats.reserved }}</h4> <!-- Keep count on the same line -->
      </div>
    </b-card>
  </b-col>

  <b-col md="4" class="mb-2">
    <b-card
      title="Rejected Reservations"
      class="text-start"
      variant="danger"
      :body-class="'d-flex flex-column align-items-start justify-content-center'" 
      style="background-color: #dc3545;"
    >
      <div class="d-flex align-items-center">
        <b-icon icon="x-circle" class="mr-2" font-scale="2" variant="light" />
        <h4 class="mb-0">{{ reservationStats.rejected }}</h4> <!-- Keep count on the same line -->
      </div>
    </b-card>
  </b-col>
</b-row>



    <!-- Tab Switching -->
    <b-row>
      <b-col>
        <v-tabs v-model="currentTab">
          <v-tab>Calendar View</v-tab>
          <v-tab>Grid View</v-tab>
        </v-tabs>

        <v-tabs-items v-model="currentTab">
          <!-- Calendar View Tab Content -->
          <v-tab-item>
            <v-card>
                <CalendarView ></CalendarView>
            </v-card>
          </v-tab-item>

          <!-- Table View Tab Content -->
          <v-tab-item>
            <v-card>
              <TableVue ></TableVue>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </b-col>
    </b-row>

    <!-- Debugging currentTab value -->
    <div>Current Tab Index: {{ currentTab }}</div>
  </b-container>
</template>

<script>
import TableVue from './components/ReservationsTableWidget.vue'
import CalendarView from './components/reservationscalenderview.vue'

import { mapGetters } from "vuex";
import { BUSINESS_RESERVATIONS_UPDATE, RESERVATIONS_STATS } from "@/core/services/store/config.module";

export default {
  components: {
    TableVue,
    CalendarView
  },
  data() {
    return {
      currentTab: 0, // Default tab is the first one
      form: {
        reservations: [],
        currentPage: 1,
        itemsPerPage: 10,
      },
      currentPage: 1,
      itemsPerPage: 10,
      reservationStats:{
        pending: 0,
        reserved: 0,
        rejected: 0,
      }
    };
  },

  methods: {
    loadStats(page = 1) {
      this.loading = true;
      this.$store
        .dispatch(RESERVATIONS_STATS)
        .then((response) => {
          console.log('reservationStats',response)
          this.reservationStats.pending=response.pending
          this.reservationStats.rejected=response.rejected
          this.reservationStats.reserved=response.reserved

          this.loading = false;
        })
        .catch((error) => {
          console.error(error);
          this.loading = false;
        });
    }
  },
  mounted() {
    this.loadStats();
  },
};
</script>

<style scoped>
.table-body {
  margin-top: 0px !important;
}
</style>
